<template>
  <!-- 发起售后结果 -->
  <Layout>
    <div class="main-pay-success">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
        <!-- 售后信息 -->
        <div class="md-after-sales c-card">
          <div class="tips s22 font-medium color-2 text-center">
            换货/补货售后发起成功，卖家将在
            <span class="color-red">14天59分58秒</span>
            内处理
          </div>
          <router-link class="seller flex-center s16" to="/shop/seller">
            <img src="@/assets/images/icon-89.png" alt="" class="avatar">
            <p>易筑官方旗舰店</p>
          </router-link>
          <div class="goods s16 color-4 flex-center">
            <img src="@/assets/images/prod-4.jpg" alt="" class="cover">
            <div class="text">
              <div class="t"><span>商品标题：</span>泳池水下灯</div>
              <div class="t"><span>规格：</span>50*50</div>
              <div class="t">部分售后</div>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="flex-center btns" v-ani.fade-up>
          <BaseButton tag='router-link' to='/order-detail' size='large'>查看订单详情</BaseButton>
          <BaseButton tag='router-link' to='/' size='large' style-type='light'>返回首页</BaseButton>
        </div>
        <!-- 其他热推 -->
        <div class="md-prod-recommend">
          <div class="c-title-2 s22" v-ani.fade-up>其他热推</div>
          <div class="list flex-wrap">
            <template v-for="item in mockList">
              <ProductItem :value="item" :key="item.id" v-ani.fade-up />
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        { name: '首页', link: '/'},
        { name: '我的订单', link: '/order'},
        { name: '全部订单', link: '/order'},
        { name: '发起售后', link: '' }
      ],
      mockList: [
        {
          id: 1,
          img: require('@/assets/images/prod-1.jpg'),
          title: '水性钢结构防护彩钢瓦漆铁红中灰金属防锈底漆水性工业漆',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 2,
          img: require('@/assets/images/prod-2.jpg'),
          title: '水上乐园挂壁式泳池防水水下灯LED水底灯',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 3,
          img: require('@/assets/images/prod-3.jpg'),
          title: '佛山瓷砖800x800客厅地砖全瓷通体大理石地板砖',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 4,
          img: require('@/assets/images/prod-4.jpg'),
          title: '幻彩漆金属肌理漆天鹅绒内墙漆艺术涂料',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 5,
          img: require('@/assets/images/prod-1.jpg'),
          title: '水性钢结构防护彩钢瓦漆铁红中灰金属防锈底漆水性工业漆',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 6,
          img: require('@/assets/images/prod-2.jpg'),
          title: '水上乐园挂壁式泳池防水水下灯LED水底灯',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.main-pay-success{
  padding-bottom: .4rem;
  .btns{
    margin-top: .39rem;
    .base-button{
      min-width: 2rem;
      margin: 0 .19rem 0 0;
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
  .md-prod-recommend{
    margin-top: .38rem;
    .list{
      margin-top: .15rem; margin-bottom: -.2rem;
      flex-wrap: wrap;
    }
    .c-product-item{
      width: 2.5rem;
      height: auto;
      margin-right: .2rem; margin-bottom: .2rem;
      &:nth-of-type(6n){
        margin-right: 0;
      }
      ::v-deep .goods .tit{
        margin-top: .12rem;
      }
    }
  }
  .md-after-sales{
    padding: .36rem .4rem .4rem;
    .tips{}
    .seller{
      margin-top: .2rem;
      .avatar{
        width: .2rem; height: .2rem;
        object-fit: contain;
        margin-right: .07rem;
      }
    }
    .goods{
      width: 4.18rem;
      border-radius: 0.04rem; overflow: hidden;
      margin: .14rem auto 0;
      border: solid 0.01rem #e6f2f8;
      .cover{
        width: 1.5rem; height: 1.5rem;
        margin-right: .29rem;
      }
      .text{
        margin-top: .12rem;
        flex: 1;
        line-height: calc(30/ 16 * 1em);
        span{
          color: #999;
        }
        .t{
          margin-bottom: .02rem;
        }
      }
    }
  }
}
</style>